import { createTheme as muiCreateTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';

import { initializePalette } from '@agency/theme/units/palette';
import { initializeOverrides } from '@shared/theme/overrides';
import { initializeBreakpoints } from '@shared/theme/units/breakpoints';
import { initializeComponentsProps } from '@shared/theme/units/components-props';
import { initializeDirection } from '@shared/theme/units/direction';
import { initializeMixins } from '@shared/theme/units/mixins';
import { initializeShape } from '@shared/theme/units/shape';
import { initializeSpacing } from '@shared/theme/units/spacing';
import { initializeTransitions } from '@shared/theme/units/transitions';
import { initializeTypography } from '@shared/theme/units/typography';

export function createTheme(options?: ThemeOptions) {
  const result: ThemeOptions = {
    breakpoints: initializeBreakpoints(options?.breakpoints),
    direction: initializeDirection(options?.direction),
    mixins: initializeMixins(options?.mixins),
    palette: initializePalette(options?.palette),
    props: initializeComponentsProps(options?.props),
    shape: initializeShape(options?.shape),
    spacing: initializeSpacing(options?.spacing),
    transitions: initializeTransitions(options?.transitions),
    typography: initializeTypography(options?.typography),
  };

  return muiCreateTheme({
    ...result,
    overrides: initializeOverrides(result.overrides, muiCreateTheme(result)),
  });
}
