// Buttons
export enum ButtonColour { // TODO: Legacy, remove this when resource is available
  error = 'legacy_error',
  primary = 'legacy_primary',
  primaryGhost = 'legacy_primary-ghost',
  primarySpecial = 'legacy_primarySpecial',
  secondary = 'legacy_secondary',
  secondaryColour = 'legacy_secondary-colour',
  secondaryGhost = 'legacy_secondary-ghost',
  secondaryGhost2 = 'legacy_secondaryGhost2',
  secondaryGhost3 = 'legacy_secondaryGhost3',
  teal = 'legacy_teal',
}

export enum NewButtonColour { // TODO: Legacy, rename this to ButtonColour when resource is available
  Danger = 'Danger', // error
  Ghost = 'Ghost',
  Outline = 'Outline',
  PrimaryNavy = 'PrimaryNavy', // primary
  PrimarySalmon = 'PrimarySalmon', // primarySpecial
  PrimaryTeal = 'PrimaryTeal', // teal
  Subtle = 'Subtle',
  Transparent = 'Transparent',
}
export type NewButtonColourType = keyof typeof NewButtonColour;

// NOTE: Keep these in sync
export type SelectColour = NewButtonColour.Outline | NewButtonColour.Ghost;
export const selectColours: SelectColour[] = [NewButtonColour.Outline, NewButtonColour.Ghost];

export enum ButtonSize {
  extraSmall = 'extraSmall',
  small = 'small',
  mediumLegacy = 'mediumLegacy', // NOTE: Legacy to support 36px height, i.e. our existing inputs
  medium = 'medium',
  large = 'large',
  // Icon sizes
  iconSmall = 'iconSmall',
  iconLarge = 'iconLarge',
  iconOnly = 'iconOnly',
}

// Selects
export enum SelectMenuPosition {
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
}
