const katoColours = {
  // ----- Design system colours -----

  // Named as "Base" in the design system
  solid: {
    white: '#FFFFFF', // 25
    black: '#000000', // 50
  },

  neutral: {
    50: '#F7F8FA',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D1D6DF',
    400: '#9EA6B3',
    500: '#7E8899', // Base
    600: '#515D70',
    700: '#344154',
    800: '#1D2739',
    900: '#101828',
  },

  brandSalmon: {
    50: '#FFF5F5',
    100: '#FFE5E8',
    200: '#FFCAD2',
    300: '#FF9FAC',
    400: '#FF6981',
    500: '#FF4A6B', // Base
    600: '#ED1143',
    700: '#C80839',
    800: '#A80937',
    900: '#8F0C35',
  },

  brandNavy: {
    50: '#F0F3FD',
    100: '#E3E9FC',
    200: '#CDD5F8',
    300: '#AEBAF3',
    400: '#8D97EC',
    500: '#7171E3', // Base
    600: '#4F4AB8',
    700: '#3F3A99',
    800: '#312E73',
    900: '#201E42',
  },

  success: {
    50: '#ECFDF3',
    100: '#DEFAE2',
    200: '#BEF4C6',
    300: '#8BEA9A',
    400: '#61DA75',
    500: '#2ABD43', // Base
    600: '#1D9C33',
    700: '#1A7B2B',
    800: '#1A6127',
    900: '#175023',
  },

  warning: {
    50: '#FFF5EB',
    100: '#FDE9D5',
    200: '#FCDEC0',
    300: '#FFD1A3',
    400: '#FEB872',
    500: '#F59638', // Base
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },

  error: {
    50: '#FFF3F1',
    100: '#FFE4E0',
    200: '#FFCDC6',
    300: '#FFAB9E',
    400: '#FF7B67',
    500: '#FC573E', // Base
    600: '#EA3418',
    700: '#C52810',
    800: '#A32411',
    900: '#862516',
  },

  // ----- Other (not in the design system) -----

  brandTeal: {
    50: '#EFF8F7',
    100: '#D7EDE8',
    200: '#ADE2D3',
    300: '#7CCCB8',
    400: '#50B19B',
    500: '#3CA48E',
    600: '#2A7769',
    700: '#256056',
    800: '#214E46',
    900: '#1F423B',
    950: '#0D2622',
  },

  yellow: {
    50: '#FFFAF0',
    100: '#FFEDCB',
    200: '#FFEC85',
    300: '#FFDC46',
    400: '#FFC91B',
    500: '#FFA700',
    600: '#E27E00',
    700: '#BB5702',
    800: '#984308',
    900: '#7C370B',
    950: '#481B00',
  },

  pink: {
    50: '#FFF9FD',
    100: '#FFE5F1',
    200: '#FECCE6',
    300: '#FFB1D9',
    400: '#FE68B1',
    500: '#F83C93',
    600: '#E81A70',
    700: '#CA0C55',
    800: '#A70D46',
    900: '#8B103D',
    950: '#550220',
  },

  grey: {
    50: '#F0F3FD',
    100: '#F6F6F8',
    200: '#EDECF1',
    300: '#D2D2DD',
    400: '#B7B8C7',
    500: '#A1A2B3',
    600: '#6D6F85',
    700: '#505267',
    800: '#3D4054',
    900: '#252839',
    950: '#161729',
  },
};

export const colours = {
  ...katoColours,
  // Aliases for old names
  green: { ...katoColours.success },
  navy: { ...katoColours.brandNavy },
  red: { ...katoColours.error },
  salmon: { ...katoColours.brandSalmon },
  teal: { ...katoColours.brandTeal },
};

export const hexToRgba = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const legacyColours = {
  blue: {
    lightest: '#F5FAFF',
    lighter: '#E6F2FF',
    light: '#E6F2FF',
    lightish: '#CBE3FF',
    main: '#487EF4',
    medium: '#0077ff',
    darkish: '#024795',
    dark: '#324DFD',
    darker: '#11284F',
    darkest: '#182847',
  },
  grey: {
    lightest: '#EAEBEA', // angular - $faintGrey
    lighter: '#f1f3f5', // angular - $lightergrey
    light: '#DDE1E6', // angular - $lightgrey
    lightish: '#BFC5D1', // angular - $mediumgrey / $mediumishgrey. Same as #b5bbc6
    main: '#858D9D', // angular -  $grey. Same as #828D9E
    darkish: '#6B788E', // angular - $darkgrey-two
    dark: '#7B879B', // angular - $darkgrey
    darker: '#4C5970', // angular - $darkergrey
    darkest: '#0a2147', // angular - darkishgrey

    //
    icon: '#A6B1BC', // angular - $icongrey
    body: '#F6F7F9', // angular - $bodygrey. Same as #f5f7f9
    fainter: '#D9DDE4', // angular - $faintergrey. Same as #D9DBE2
  },
  red: {
    lightest: '#FEF3F2',
    lighter: '#FBE2E5',
    light: '#FF7F90',
    lightish: '',
    main: '#F14C5D',
    darkish: '#ED1C24',
    dark: '#DB4950',
    darker: '',
    darkest: '#B42318',
  },
  green: {
    lightest: '#D2F1E1',
    lighter: '',
    light: '#9CE1BD',
    lightish: '#61D076',
    main: '#39C37B',
    darkish: '#3AC454',
    dark: '',
    darker: '#1D864F',
    darkest: '#085E44',
  },
  white: {
    lightest: '#FFFFFF',
    light: '#FCFCFC', // angular - $offwhite
    main: '#FAFAFB', // angular - $mediumwhite
  },
  black: {
    lightest: '#19273C', // Same as #1D2639
    lighter: '',
    light: '',
    lightish: '',
    main: '',
    darkish: '',
    dark: '#1D2639',
    darker: '#001234', // Same as #001334, #101828
    darkest: '#000000',
  },
  purple: {
    lightest: '',
    lighter: '#EEF0FF', //same as #EBEEFF
    light: '#EBE1F8',
    lightish: '',
    main: '#50218C',
    darkish: '',
    dark: '#2a283d',
    darker: '',
    darkest: '',
  },
  orange: {
    lightest: '',
    lighter: '#FFF2E7',
    light: '#FCEADB',
    lightish: '#FF9A3E',
    main: '#F2944D',
    darkish: '#D9A200',
    dark: '#F0802C',
    darker: '#C27127',
    darkest: '',
  },
};

// For existing imports of colors
export const colors = {
  kato: { ...colours },
  ...legacyColours,
};
