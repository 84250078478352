import { DisposalShowItem } from '@agency/apis/disposals';
import { IUser } from '@shared/models/IUser';

import { ObjectType } from './object';

export type PusherDuration = 5000 | 10000 | 20000;

export enum AgencyPageIds {
  DisposalInterestScheduleSpace = 'DisposalInterestScheduleSpace',
}

// --- Keys ---
// NOTE: It's very important to include "." at the beginning of the key

// Disposal (Env)
export enum PusherKeyEnvDisposal {
  DisposalCountsUpdated = '.DisposalCountsUpdated',
  InterestCountsUpdated = '.InterestCountsUpdated',
  InterestMovedToSchedule = '.InterestMovedToSchedule',
}

// Object (Env)
export enum PusherKeyEnvObject {
  ObjectAdded = '.ObjectAdded',
  ObjectDeleted = '.ObjectDeleted',
  ObjectUpdated = '.ObjectUpdated',
  ObjectsUpdated = '.ObjectsUpdated',
}

// Society (All)
export enum PusherKeySociety {
  // TODO: These should be scoped to the env. Currently emit on all envs
  AcquisitionPublishedToSociety = '.AcquisitionPublishedToSociety',
  LettingPublishedToSociety = '.LettingPublishedToSociety',
}

// User (All)
export enum PusherKeyUser {
  // TODO: These should be scoped to the env. Currently emit on all envs
  BulkActionCompleted = '.BulkActionCompleted',
  BulkActionProgress = '.BulkActionProgress',
}

// User (Env)
export enum PusherKeyEnvUser {
  ScheduleMatchAgentMessageCreated = '.ScheduleMatchAgentMessageCreated',
}

export type PusherKey = PusherKeyEnvDisposal | PusherKeyEnvObject | PusherKeyEnvUser | PusherKeySociety | PusherKeyUser;

// --- Payloads ---

interface LegacyEventPayload {
  id: number;
  user: IUser; // TODO: Check ok
  headline: string;
  subline: string;
  message: string;
}

export interface DisposalObjectPayload {
  disposal_id: number;
  object_type: ObjectType.Disposal;
}

export interface ScheduleMatchObjectPayload {
  disposal_id: number;
  object_type: ObjectType.ScheduleMatch;
  schedule_match_id: number;
}

export interface ScheduleMatchMessageObjectPayload extends Omit<ScheduleMatchObjectPayload, 'object_type'> {
  message_id: number;
  object_type: ObjectType.ScheduleMatchMessage;
}

export type ObjectPayloads = DisposalObjectPayload | ScheduleMatchObjectPayload | ScheduleMatchMessageObjectPayload;

export interface BulkObjectPayload<TOT extends ObjectType, TOP extends ObjectPayloads> {
  object_type: TOT;
  objects: TOP[];
}

export type BulkObjectPayloads = BulkObjectPayload<ObjectType.ScheduleMatch, ScheduleMatchObjectPayload>;

export type PusherPayload = {
  // ---- Disposal (Env) ----
  [PusherKeyEnvDisposal.DisposalCountsUpdated]: {
    disposal_id: number;
    counts: DisposalShowItem['counts'];
  };

  [PusherKeyEnvDisposal.InterestCountsUpdated]: {
    counts: {
      messages: number;
    };
    interest_id: number;
    is_last_sender: boolean;
  };

  [PusherKeyEnvDisposal.InterestMovedToSchedule]: {
    id: number;
  };

  // ---- Object (Env) ----

  [PusherKeyEnvObject.ObjectAdded]: ObjectPayloads;

  [PusherKeyEnvObject.ObjectDeleted]: ObjectPayloads;

  [PusherKeyEnvObject.ObjectUpdated]: ObjectPayloads;

  [PusherKeyEnvObject.ObjectsUpdated]: BulkObjectPayloads;

  // ---- Society (All) ----

  [PusherKeySociety.AcquisitionPublishedToSociety]: LegacyEventPayload;

  [PusherKeySociety.LettingPublishedToSociety]: LegacyEventPayload;

  // ---- User (All) ----

  [PusherKeyUser.BulkActionCompleted]: {
    failed_text: string | null;
    job_id: number;
    subtext: string;
    success_text: string;
    title: string;
    type: PusherKeyUser.BulkActionCompleted;
    url_text: string | null;
    url: string | null;
  };

  [PusherKeyUser.BulkActionProgress]: {
    job_id: number;
    progress: number;
    subtext: string;
    title: string;
    type: PusherKeyUser.BulkActionProgress;
  };

  // ---- User (Env) ----

  [PusherKeyEnvUser.ScheduleMatchAgentMessageCreated]: {
    disposal_id: number;
    disposal_organisation_id: number;
    message: {
      body: string; // TODO: Check ok
      id: number;
      object_id: number;
      user_id: number;
      user: IUser; // TODO: Check ok
    };
    requirement_id: number;
    requirement_organisation_id: number;
  };
};

// --- Notification Keys ---

export enum PusherNotificationKey {
  UserOrTeamMentionedOnInterestScheduleComment = 'userOrTeamMentionedOnInterestScheduleComment',
  UserOrTeamMentionedOnRequirementComment = 'userOrTeamMentionedOnRequirementComment',
  UserOrTeamMentionedOnShortlistComment = 'userOrTeamMentionedOnShortlistComment',
  UserOrTeamMentionedOnSocietyAcquisitionComment = 'userOrTeamMentionedOnSocietyAcquisitionComment',
}

// --- Notification Payloads ---

export type PusherNotificationPayload = {
  [PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment]: {
    disposal_id: number;
    headline: string;
    id: number;
    match_id: number;
    message: string;
    subline: string;
    type: PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment;
    user: IUser; // TODO: Check ok
  };

  [PusherNotificationKey.UserOrTeamMentionedOnRequirementComment]: {
    acquisition_id: number;
    headline: string;
    id: number;
    message: string;
    subline: string;
    type: PusherNotificationKey.UserOrTeamMentionedOnRequirementComment;
    user: IUser; // TODO: Check ok
  };

  [PusherNotificationKey.UserOrTeamMentionedOnShortlistComment]: {
    headline: string;
    id: number;
    match_id: number;
    message: string;
    requirement_id: number;
    subline: string;
    type: PusherNotificationKey.UserOrTeamMentionedOnShortlistComment;
    user: IUser; // TODO: Check ok
  };

  [PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment]: {
    headline: string;
    id: number;
    message: string;
    society_acquisition_id: number;
    subline: string;
    type: PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment;
    user: IUser; // TODO: Check ok
  };
};
