import {
  QueryClient,
  QueryKey,
  useMutation as useReactMutation,
  UseMutationOptions as UseReactMutationOptions,
  UseMutationResult as UseReactMutationResult,
  useQuery as useReactQuery,
  UseQueryOptions as UseReactQueryOptions,
  UseQueryResult as UseReactQueryResult,
} from '@tanstack/react-query';
import Axios, { AxiosError, AxiosInstance, AxiosResponse, CancelTokenSource } from 'axios';
import { pick } from 'lodash';

import { DisposalInboundIndexResponse } from '@agency/apis/disposals';
import { getBaseUrl } from '@core/base-url';
import { showNotification } from '@shared/components/notification';
import { IndexResponse, ListResponsePagination } from '@shared/types/services';
import { forStringRecord, getDefaultError, getQueriesAsSearch } from '@shared/utils/common';
import { getConfig } from '@shared/utils/window';

interface TraceItem {
  file: string;
  line: number;
  function: string;
  class: string;
  type: string;
}

export interface ErrorWithTrace {
  exception: string;
  file: string;
  line: number;
  message: string;
  trace: TraceItem[];
}

export interface MessageAndErrors {
  errors: string[];
  message: string;
}

interface ErrorWithStatus {
  detail: string;
  status: number;
}

export interface MessageAndErrorsWithStatus {
  errors: ErrorWithStatus[];
  message: string;
}

export interface MessageAndGroupedErrors {
  errors: Record<string, string[]>;
  message: string;
}

export type AnyErrorResponse = ErrorWithTrace | MessageAndErrors | MessageAndGroupedErrors | MessageAndErrorsWithStatus;

export const defaultPagination: ListResponsePagination = {
  count: 0,
  current_page: 1,
  from: 0,
  to: 0,
  links: {
    last: '',
    next: '',
  },
  per_page: 0,
  total: 0,
  total_pages: 0,
  last_page: 0,
};

export type SuccessResponse<T = any> = AxiosResponse<T>;

export interface FailureResponse<T = any> extends AxiosError<T> {
  errorMessages: string[];
}

export interface YieldResponse<T1 = any, T2 = any> {
  response?: SuccessResponse<T1>;
  error?: FailureResponse<T2>;
}

export type YieldSuccessResponse = (response: AxiosResponse<any>) => YieldResponse;

export type YieldFailureResponse = (error: AxiosError<any>) => YieldResponse;

export const yieldSuccessResponse: YieldSuccessResponse = (response) => {
  return { response } as YieldResponse;
};

export const yieldFailureResponse: YieldFailureResponse = (error) => {
  return { error } as YieldResponse;
};

// TODO: Make prefix default to ''
export const getHTTPClient = (prefix = 'api/', subDomain?: string): AxiosInstance => {
  const instance = Axios.create();
  instance.defaults.baseURL = `${getBaseUrl(subDomain)}${prefix}`;

  instance.interceptors.request.use((config) => ({
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      ...(!!getConfig()?.coogan && { 'X-COOGAN': true }),
    },
  }));

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;

      if (status === 401) {
        window.location.href = `${getBaseUrl()}logout${getQueriesAsSearch({ accountLevel: data?.accountLevel || '' })}`;
      }

      if (status === 412) {
        window.location.href = 'https://agents-society.com/terms';
      }

      if (status === -1) {
        if (error?.response?.config?.timeout) {
          return;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const getExternalHTTPClient = (baseUrl: string): AxiosInstance => {
  const instance = Axios.create();
  instance.defaults.baseURL = baseUrl;

  return instance;
};

export const getCancelRequestSource = (): CancelTokenSource => Axios.CancelToken.source(); // TODO remove deprecated method after Axios update

export const getDefaultIndexResponse = <TD = unknown>(): IndexResponse<TD> => ({
  data: [],
  meta: {
    pagination: defaultPagination,
  },
});

// Use Mutation
export type UseMutationOptions<
  TVariables = unknown,
  TData = unknown,
  TError = unknown,
  TContext = unknown
> = UseReactMutationOptions<TData, AxiosError<TError>, TVariables, TContext>;

export const useMutation = <TVariables = unknown, TData = unknown, TError = unknown, TContext = unknown>(
  options: UseReactMutationOptions<TData, AxiosError<TError>, TVariables, TContext>
): UseReactMutationResult<TData, AxiosError<TError>, TVariables, TContext> =>
  useReactMutation<TData, AxiosError<TError>, TVariables, TContext>(options);

// Use Query
export type UseQueryOptions<TData = unknown, TError = unknown, TQueryFnData = TData> = UseReactQueryOptions<
  TQueryFnData,
  AxiosError<TError>,
  TData,
  any
>;

export type QueryFactory<T = unknown> = (payload: any) => UseQueryOptions<T>;

/**
 * @deprecated This wrapper for useQuery is deprecated and should not be used.
 * Please import { useQuery } from '@tanstack/react-query' instead.
 */
export const useQuery = <TQK extends QueryKey = QueryKey, TData = unknown, TError = unknown, TQueryFnData = TData>(
  options: UseReactQueryOptions<TQueryFnData, AxiosError<TError>, TData, TQK>
): UseReactQueryResult<TData, AxiosError<TError>> =>
  useReactQuery<TQueryFnData, AxiosError<TError>, TData, TQK>(options);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      onError: () => showNotification(getDefaultError(), 'error'),

      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
      onError: () => showNotification(getDefaultError(), 'error'),
    },
  },
});

export const cancelQueries = (options: UseQueryOptions) => queryClient.cancelQueries(pick(options, ['queryKey']));

export type UpdateCacheCallback<T = unknown> = (cache: T) => T;

export const updateQueryCache = <TD = unknown>(queryKey: QueryKey, callback: UpdateCacheCallback<TD>) =>
  queryClient.setQueryData<TD>(queryKey, (cache) => (cache ? callback(cache) : cache));

export const clearIndexQuery = <TD extends ItemWithId = ItemWithId>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, () =>
    getDefaultIndexResponse()
  );
};

export const legacyUpdateIndexQueryItem = <TD extends ItemWithId = ItemWithId, TI extends Id = Id>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  id: TI,
  data: TD,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, (indexResponse) => {
    if (indexResponse) {
      return {
        ...indexResponse,
        data: indexResponse.data.map((item) => (item.id === id ? data : item)),
      };
    }

    return indexResponse;
  });
};

export const updateIndexQueryItem = <TD extends ItemWithId = ItemWithId>(
  queryOptions: UseQueryOptions<IndexResponse<TD>>,
  itemId: TD['id'],
  updateCb: (item: TD) => TD
): void => {
  queryClient.setQueriesData<IndexResponse<TD> | undefined>(
    { exact: false, queryKey: queryOptions.queryKey },
    (response) => {
      if (!response) {
        return response;
      }

      return {
        ...response,
        data: response.data.map((item) => (item.id === itemId ? updateCb({ ...item }) : item)),
      };
    }
  );
};

export const updateIndexQueryItemAndGetPrevious = <TD extends ItemWithId = ItemWithId>(
  queryOptions: UseQueryOptions<IndexResponse<TD>>,
  itemId: TD['id'],
  updateCb: (item: TD) => TD
): TD | undefined => {
  const previous = queryClient.getQueryData<IndexResponse<TD> | undefined>(queryOptions.queryKey);

  updateIndexQueryItem(queryOptions, itemId, updateCb);

  return previous?.data.find((item) => item.id === itemId);
};

//TODO To remove it
export const updateShowIndexItem = <TD extends DisposalInboundIndexResponse>(
  queryOptions: UseQueryOptions<TD>,
  itemId: number,
  updateCb: (item: TD) => TD
): void => {
  queryClient.setQueriesData<TD | undefined>({ exact: false, queryKey: queryOptions.queryKey }, (items) => {
    if (!items) {
      return items;
    }

    const hasItem = items.data.find((item) => item.id === itemId);

    return hasItem ? updateCb(items) : items;
  });
};

export const updateQueryItem = <TD extends ItemWithId = ItemWithId>(
  queryOptions: UseQueryOptions<TD>,
  itemId: TD['id'],
  updateCb: (item: TD) => TD
): void => {
  queryClient.setQueriesData<TD | undefined>({ exact: false, queryKey: queryOptions.queryKey }, (item) => {
    if (!item) {
      return item;
    }

    return item.id === itemId ? updateCb({ ...item }) : item;
  });
};

export const updateQueryItemAndGetPrevious = <TD extends ItemWithId = ItemWithId>(
  queryOptions: UseQueryOptions<TD>,
  itemId: TD['id'],
  updateCb: (item: TD) => TD
): TD | undefined => {
  const previous = queryClient.getQueryData<TD | undefined>(queryOptions.queryKey);

  updateQueryItem(queryOptions, itemId, updateCb);

  return previous;
};

export const deleteIndexQueryItem = <TD extends ItemWithId = ItemWithId, TI extends Id = Id>(
  queryFactory: QueryFactory<IndexResponse<TD>>,
  id: TI,
  params?: Query
) => {
  queryClient.setQueryData<IndexResponse<TD>>(queryFactory({ query: { ...params } }).queryKey, (indexResponse) => {
    if (indexResponse) {
      return {
        ...indexResponse,
        data: indexResponse.data.filter((item) => item.id !== id),
      };
    }

    return indexResponse;
  });
};

export const getErrorsFromResponse = (error: AxiosError<AnyErrorResponse>): string[] => {
  // console.log('getErrorsFromResponse. response: ', data.response);

  if (!error.response) {
    return [];
  }

  const data = error.response.data;

  const mandatoryFieldsErrors: string[] = [];
  if ('trace' in data) {
    return [data.message];
  } else if (Array.isArray(data.errors)) {
    // console.log('getErrorsFromResponse. processing array...');
    data.errors.forEach((item) => {
      let message = '';

      if (typeof item === 'string') {
        // Type: string
        message = item;
        // console.log('getErrorsFromResponse. string item: ', item);
      } else if (typeof item === 'object') {
        // Type: ErrorWithStatus
        message = item.detail;
        // console.log('getErrorsFromResponse. ErrorWithStatus item: ', item);
      }

      if (message && !mandatoryFieldsErrors.includes(message)) {
        mandatoryFieldsErrors.push(message);
      }
    });

    return mandatoryFieldsErrors;
  } else if (data.errors && Object.keys(data.errors).length) {
    // console.log('data.response.data.errors: ', data.response.data.errors);
    forStringRecord(data.errors, (errors) => {
      // console.log('getErrorsFromResponse. forStringRecord errors: ', errors);
      errors.forEach((message) => {
        if (message && !mandatoryFieldsErrors.includes(message)) {
          mandatoryFieldsErrors.push(message);
        }
      });
    });

    return mandatoryFieldsErrors;
  } else if (data.message) {
    return [data.message];
  }

  return [];
};

// ---- Instances ----

export const $http = getHTTPClient('');
export const $httpAgency = getHTTPClient('api/');
export const $httpLandlord = getHTTPClient('landlord-api/');

// External
export const $httpPca = getExternalHTTPClient('https://services.postcodeanywhere.co.uk/capture/Interactive');
